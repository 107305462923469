import { combineReducers } from 'redux'

import annotationMenuState from './annotationMenuState'
import highlightedAnnotationId from './highlightedAnnotationId'
import hotspotDialogState from './hotspotDialogState'
import measurementSettings from './measurementSettings'
import measurementSettingsDialogState from './measurementSettingsDialogState'
import selectedAnnotationsIds from './selectedAnnotationsIds'
import textAnnotationEditorState from './textAnnotationEditorState'
import undoRedoState from './undoRedoState'
import uniqueAIObjectCreationMode from './uniqueAIObjectCreationMode'

export default combineReducers({
  annotationMenuState,
  highlightedAnnotationId,
  hotspotDialogState,
  measurementSettings,
  measurementSettingsDialogState,
  selectedAnnotationsIds,
  textAnnotationEditorState,
  undoRedoState,
  uniqueAIObjectCreationMode,
})
