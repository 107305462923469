import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from '@material-ui/core'

import { useFormik } from 'formik'
import * as yup from 'yup'

import { tenantSubdomain } from '@tabeeb/shared/forms/helpers/regexps'

import {
  noWhitespaces,
  invalidSubdomain,
  maxLengthWithName,
  requiredFieldWithName,
} from '@tabeeb/shared/utils/validationErrorMessages'
import { FetchStatus } from '@tabeeb/enums'
import { Autocomplete, Typography } from '@mui/material'
import { useStyles } from './styles'

import { tenantsActions } from '../../actions'
import { getBusinessDomainsState } from '../../selectors/businessDomains'
import { getBusinessDomains } from '../../actions/tenants'

const validationSchema = yup.object({
  Name: yup
    .string('Enter tenant name')
    .max(256, maxLengthWithName('Tenant name', 256))
    .trim(noWhitespaces)
    .required(requiredFieldWithName('Tenant name')),
  Subdomain: yup
    .string('Enter tenant subdomain')
    .max(256, maxLengthWithName('Subdomain', 256))
    .matches(tenantSubdomain, invalidSubdomain)
    .required(requiredFieldWithName('Subdomain')),
  EnvId: yup.number().min(1).required(requiredFieldWithName('Business Domain')),
})

const CreateTenantDialog = ({ isOpen, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { fetchStatus, businessDomains } = useSelector(getBusinessDomainsState)

  useEffect(() => {
    if (isOpen && businessDomains.length === 0 && fetchStatus === FetchStatus.Idle) {
      dispatch(getBusinessDomains.request())
    }
  }, [fetchStatus, businessDomains, isOpen, dispatch])

  const handleTenantSubmit = useCallback(
    (values) => {
      const model = {
        Config: '{}',
        Name: values.Name,
        Subdomain: values.Subdomain,
        SupportsPrograms: true,
        EnvId: values.EnvId,
      }

      dispatch(tenantsActions.createRequest(model))
    },
    [dispatch]
  )

  const formik = useFormik({
    initialValues: {
      Name: '',
      Subdomain: '',
      EnvId: 0,
    },
    validationSchema,
    onSubmit: handleTenantSubmit,
  })

  const handleExited = useCallback(() => {
    formik.resetForm()
  }, [formik])

  const handleBusinessDomainChange = useCallback(
    (_e, value, reason) => {
      if (reason === 'clear') {
        formik.setValues((values) => ({ ...values, EnvId: 0 }))
      } else {
        formik.setValues((values) => ({ ...values, EnvId: value.Id }))
      }
    },
    [formik]
  )

  return (
    <Dialog open={isOpen} onClose={onClose} onExited={handleExited}>
      <form noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
        <DialogTitle>Add a new tenant</DialogTitle>
        <DialogContent>
          <TextField
            required
            className={classes.formInput}
            fullWidth
            name='Name'
            id='tenant-name'
            label='Tenant name'
            variant='outlined'
            value={formik.values.Name}
            onChange={formik.handleChange}
            error={formik.touched.Name && Boolean(formik.errors.Name)}
            helperText={formik.touched.Name && formik.errors.Name}
          />
          <TextField
            required
            className={classes.formInput}
            fullWidth
            name='Subdomain'
            id='tenant-subdomain'
            label='Subdomain'
            variant='outlined'
            value={formik.values.Subdomain}
            onChange={formik.handleChange}
            error={formik.touched.Subdomain && Boolean(formik.errors.Subdomain)}
            helperText={formik.touched.Subdomain && formik.errors.Subdomain}
            InputProps={{
              endAdornment: <InputAdornment position='end'>.collaborate.center</InputAdornment>,
            }}
          />
          <Autocomplete
            required
            fullWidth
            name='EnvId'
            label='Business Domain'
            options={businessDomains}
            onChange={handleBusinessDomainChange}
            loading={fetchStatus === FetchStatus.Loading}
            error={formik.touched.EnvId && Boolean(formik.errors.EnvId)}
            helperText={formik.touched.EnvId && formik.errors.EnvId}
            renderOption={(props, option) => (
              <Typography {...props} noWrap title={option.BusinessDomainName} key={option.Id}>
                {option.BusinessDomainName}
              </Typography>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                variant='outlined'
                label='Select a Business Domain'
                placeholder='Search a Business Domain...'
              />
            )}
            getOptionLabel={(option) => option.BusinessDomainName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <Button disabled={!(formik.isValid && formik.dirty)} color='primary' type='submit'>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

CreateTenantDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default CreateTenantDialog
