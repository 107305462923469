const contractorsRequests = {
  // contracts
  getContractsRequest: () => ({
    url: `contractor-service/api/contracts`,
    method: 'get',
  }),
  getContractRequest: ({ id }) => ({
    url: `contractor-service/api/contracts/${id}`,
    method: 'get',
  }),
  createContractOrderRequest: (data) => ({
    url: `contractor-service/api/contracts/order`,
    method: 'post',
    data,
  }),
  createContractRequest: (data) => ({
    url: `contractor-service/api/contracts`,
    method: 'post',
    data,
  }),
  deleteContractRequest: (contractId) => ({
    url: `contractor-service/api/contracts/${contractId}`,
    method: 'delete',
  }),
  // contract versions
  getContractVersionRequest: ({ contractId, versionId }) => ({
    url: `contractor-service/api/contracts/${contractId}/versions/${versionId}`,
    method: 'get',
  }),
  getContractVersionsRequest: ({ contractId }) => ({
    url: `contractor-service/api/contracts/${contractId}/versions`,
    method: 'get',
  }),
  createContractVersionRequest: ({ contractId, version }) => ({
    url: `contractor-service/api/contracts/${contractId}/versions`,
    method: 'post',
    data: version,
  }),
  approveContractVersionRequest: ({ contractId, versionId }) => ({
    url: `contractor-service/api/contracts/${contractId}/versions/${versionId}/status/approve`,
    method: 'put',
  }),
  rejectContractVersionRequest: ({ contractId, versionId, data }) => ({
    url: `contractor-service/api/contracts/${contractId}/versions/${versionId}/status/reject`,
    method: 'put',
    data,
  }),
  updateContractVersionRequest: ({ contractId, versionId, version }) => ({
    url: `contractor-service/api/contracts/${contractId}/versions/${versionId}`,
    method: 'put',
    data: version,
  }),
  getUsersContractorTenantsRequest: ({ userIds }) => ({
    url: `contractor-service/api/contracts/get-users-contractor-tenants`,
    method: 'post',
    data: { userIds },
  }),
}

export default contractorsRequests
