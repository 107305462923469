import { handleActions } from 'redux-actions'

import { setBoundingBox } from '../actions'

const defaultState = {
  id: 0,
  box: {
    min: {
      x: 0,
      y: 0,
      z: 0,
    },
    max: {
      x: 0,
      y: 0,
      z: 0,
    },
  },
}

const mergeBoxes = (firstBox, secondBox) => {
  return {
    min: {
      x: Math.min(firstBox.min.x, secondBox.min.x),
      y: Math.min(firstBox.min.y, secondBox.min.y),
      z: Math.min(firstBox.min.z, secondBox.min.z),
    },
    max: {
      x: Math.max(firstBox.max.x, secondBox.max.x),
      y: Math.max(firstBox.max.y, secondBox.max.y),
      z: Math.max(firstBox.max.z, secondBox.max.z),
    },
  }
}

export default handleActions(
  {
    [setBoundingBox](state, { payload: { id, box } }) {
      const { id: prevId, box: prevBox } = state

      let boundingBox = box
      if (id === prevId) {
        boundingBox = mergeBoxes(prevBox, box)
      }

      return {
        id,
        box: {
          min: {
            ...boundingBox.min,
          },
          max: {
            ...boundingBox.max,
          },
        },
      }
    },
  },
  defaultState
)
