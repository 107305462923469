import { Euler } from 'three'

import { createSelector } from 'reselect'

import { AssetType } from '@tabeeb/enums'
import { getSelectedGalleryItem } from '@tabeeb/modules/gallery/selectors'

export const getIsDrawingEnabled = (state) => state.playerToolbar.drawing.drawingState.isDrawing
export const getDrawingAnnotationType = (state) => state.playerToolbar.drawing.drawingState.type
export const getViewerBackground = (state) => state.pointCloud.viewer.background
export const getBoundingBox = (state) => state.pointCloud.viewer.boundingBox.box
export const getSelectedModel = (state) => state.pointCloud.selectedModel
export const getFieldOfView = (state) => state.pointCloud.viewer.fieldOfView
export const getPointSize = (state) => state.pointCloud.viewer.pointSize
export const getUnit = (state) => state.pointCloud.settings.unit
export const getScale = (state) => state.pointCloud.settings.scale
export const getIsSourcePagesNavigationEnabled = (state) => state.pointCloud.navigation

export const getWorldRotation = createSelector([(state) => state.pointCloud.rotation], (rotation) => {
  return new Euler(rotation.x, rotation.y, rotation.z)
})

export const getWorldRotationCompensation = createSelector([getWorldRotation], (rotation) => {
  return new Euler(-rotation.x, -rotation.y, -rotation.z)
})

export const getTransformationState = (state) => state.pointCloud.transformation

export const getOrientationsFileUrl = createSelector([getSelectedGalleryItem], (page) => {
  return page?.assets?.find((asset) => asset.Type === AssetType.File)?.Url
})

export const getIsSourcePagesNavigationAvailable = createSelector([getOrientationsFileUrl], (url) => {
  return Boolean(url)
})
