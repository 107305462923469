const uniqueAIObjectConnectionRequests = {
  addUniqueAiObjectConnectionRequest: (data) => ({
    url: `api/ai/uniqueObjects/connection`,
    method: 'post',
    data,
  }),
  deleteUniqueAiObjectConnectionRequest: ({ uniqueAIObjectConnectionId }) => ({
    url: `api/ai/uniqueObjects/connection/${uniqueAIObjectConnectionId}`,
    method: 'delete',
  }),
}

export default uniqueAIObjectConnectionRequests
