import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/shared/utils/actions'

export const resetState = createAction('RESET_AI_STATE')

export const createTag = createApiActions('CREATE_TAG')

export const onSelectDrawingAIObject = createAction('ON_SELECT_DRAWING_AI_OBJECT')

export const restoreAiObject = createApiActions('RESTORE_AI_OBJECT')

export const getAiObject = createApiActions('GET_AI_OBJECT')
export const getAiObjects = createApiActions('GET_AI_OBJECTS')

export const getAiObjectsWithStatistics = createApiActions('GET_AI_OBJECTS_WITH_STATISTICS')

export const setConflictingAiObjects = createAction('SET_CONFLICTING_AI_OBJECTS')
export const resetConflictingAiObjects = createAction('RESET_CONFLICTING_AI_OBJECTS')

export const getAiObjectsForTagsRequest = createAction('GET_AI_OBJECTS_FOR_TAGS_REQUEST')
export const getAiObjectsForTagsSuccess = createAction('GET_AI_OBJECTS_FOR_TAGS_SUCCESS')
export const getAiObjectsForTagsFailed = createAction('GET_AI_OBJECTS_FOR_TAGS_FAILED')

export const getUniqueAiObjects = createApiActions('GET_UNIQUE_AI_OBJECTS')
export const searchUniqueAiObjects = createApiActions('SEARCH_UNIQUE_AI_OBJECTS')

export const addUniqueAiObject = createApiActions('ADD_UNIQUE_AI_OBJECT')
export const shallowCloneUniqueAiObject = createApiActions('SHALLOW_CLONE_UNIQUE_AI_OBJECT')

export const getAiCounterInfoRequest = createAction('GET_AI_COUNTER_INFO_REQUEST')
export const getAiCounterInfoSuccess = createAction('GET_AI_COUNTER_INFO_SUCCESS')
export const getAiCounterInfoFailed = createAction('GET_AI_COUNTER_INFO_FAILED')

export const checkConditionsRequest = createAction('CHECK_CONDITIONS_REQUEST')
export const checkConditionsSuccess = createAction('CHECK_CONDITIONS_SUCCESS')
export const checkConditionsFailed = createAction('CHECK_CONDITIONS_FAILED')

export const setCounterListValues = createAction('SET_COUNTER_LIST_VALUES')

export const getAiObjectsCountRequest = createAction('GET_AI_OBJECTS_COUNT_REQUEST')
export const getAiObjectsCountSuccess = createAction('GET_AI_OBJECTS_COUNT_SUCCESS')

export const setAiObjectToDelete = createAction('SET_AI_OBJECT_TO_DELETE')
export const deleteAiObject = createApiActions('DELETE_AI_OBJECT')

export const updateAiObject = createApiActions('UPDATE_AI_OBJECT')

export const addAiObject = createApiActions('ADD_AI_OBJECT')

export const getPredictionSet = createApiActions('GET_PREDICTION_SET')
export const getPredictionSets = createApiActions('GET_PREDICTION_SETS')

export const addPredictionSet = createApiActions('ADD_PREDICTION_SET')

export const openAttachAiModelDialog = createAction('OPEN_ATTACH_AI_MODEL_DIALOG')
export const closeAttachModelDialog = createAction('CLOSE_ATTACH_MODEL_DIALOG')

export const updatePredictionSet = createApiActions('UPDATE_PREDICTION_SET')

export const setDeletePredictionSet = createAction('SET_DELETE_PREDICTION_SET')
export const deletePredictionSet = createApiActions('DELETE_PREDICTION_SET')

export const getUnattachedAiModels = createApiActions('GET_UNATTACHED_AI_MODELS')

export const addAiModel = createApiActions('ADD_AI_MODEL')
export const updateAiModel = createApiActions('UPDATE_AI_MODEL')
export const deleteAiModel = createApiActions('DELETE_AI_MODEL')

export const setSelectedAiObject = createAction('SET_SELECTED_AI_OBJECT')

export const setUniqueAiObjectAnnotationForEdit = createAction('SET_UNIQUE_AI_OBJECT_ANNOTATION_FOR_EDIT')
export const setUniqueAiObjectForEdit = createAction('SET_UNIQUE_AI_OBJECT_FOR_EDIT')
export const getUniqueAiObjectForEditRequest = createAction('GET_UNIQUE_AI_OBJECT_FOR_EDIT_REQUEST')
export const getUniqueAiObjectForEditSuccess = createAction('GET_UNIQUE_AI_OBJECT_FOR_EDIT_SUCCESS')
export const getUniqueAiObjectForEditFailed = createAction('GET_UNIQUE_AI_OBJECT_FOR_EDIT_FAILED')

export const getUniqueAiObject = createApiActions('GET_UNIQUE_AI_OBJECT')

export const updateUniqueAiObjectRequest = createAction('UPDATE_UNIQUE_AI_OBJECT_REQUEST')
export const updateUniqueAiObjectSuccess = createAction('UPDATE_UNIQUE_AI_OBJECT_SUCCESS')
export const updateUniqueAiObjectFailed = createAction('UPDATE_UNIQUE_AI_OBJECT_FAILED')

export const detachStructureModelAiObjectsRequest = createAction('DETACH_STRUCTURE_MODEL_AI_OBJECTS_REQUEST')
export const detachStructureModelAiObjectsSuccess = createAction('DETACH_STRUCTURE_MODEL_AI_OBJECTS_SUCCESS')

export const regenerateStructureModelRequest = createAction('REGENERATE_STRUCTURE_MODEL_REQUEST')
export const regenerateStructureModelSuccess = createAction('REGENERATE_STRUCTURE_MODEL_SUCCESS')
export const regenerateStructureModelFailed = createAction('REGENERATE_STRUCTURE_MODEL_FAILED')

export const getAiCounterInfoByFormRecordRequest = createAction('GET_AI_COUNTER_INFO_BY_FORM_RECORD_REQUEST')
export const getAiCounterInfoByFormRecordSuccess = createAction('GET_AI_COUNTER_INFO_BY_FORM_RECORD_SUCCESS')
export const getAiCounterInfoByFormRecordFailed = createAction('GET_AI_COUNTER_INFO_BY_FORM_RECORD_FAILED')

export const getNlpModel = createApiActions('GET_NLP_MODEL')
export const getNlpModels = createApiActions('GET_NLP_MODELS')

export const addNlpModel = createApiActions('ADD_NLP_MODEL')
export const updateNlpModel = createApiActions('UPDATE_NLP_MODEL')

export const setDeleteNlpModel = createAction('SET_DELETE_NLP_MODEL')
export const deleteNlpModel = createApiActions('DELETE_NLP_MODEL')

export const getVqaModel = createApiActions('GET_VQA_MODEL')
export const getVqaModels = createApiActions('GET_VQA_MODELS')

export const addVqaModel = createApiActions('ADD_VQA_MODEL')
export const updateVqaModel = createApiActions('UPDATE_VQA_MODEL')

export const setDeleteVqaModel = createAction('SET_DELETE_VQA_MODEL')
export const deleteVqaModel = createApiActions('DELETE_VQA_MODEL')

export const getTagsWithAiObjectsRequest = createAction('GET_TAGS_WITH_AI_OBJECTS_REQUEST')
export const getTagsWithAiObjectsSuccess = createAction('GET_TAGS_WITH_AI_OBJECTS_SUCCESS')
export const getTagsWithAiObjectsFailed = createAction('GET_TAGS_WITH_AI_OBJECTS_FAILED')

export const setSelectedTagForAiObjectAttach = createAction('SET_SELECTED_TAG_FOR_AI_OBJECT_ATTACH')

export const attachAiObjectToTagRequest = createAction('ATTACH_AI_OBJECT_TO_TAG_REQUEST')
export const attachAiObjectToTagSuccess = createAction('ATTACH_AI_OBJECT_TO_TAG_SUCCESS')
export const attachAiObjectToTagFailed = createAction('ATTACH_AI_OBJECT_TO_TAG_FAILED')

export const detachAiObjectFromTagRequest = createAction('DETACH_AI_OBJECT_FROM_TAG_REQUEST')
export const detachAiObjectFromTagSuccess = createAction('DETACH_AI_OBJECT_FROM_TAG_SUCCESS')
export const detachAiObjectFromTagFailed = createAction('DETACH_AI_OBJECT_FROM_TAG_FAILED')

export const openEditAiClassDependentModelsDialog = createAction('OPEN_EDIT_AI_CLASS_DEPENDENT_MODELS_DIALOG')
export const closeEditAiClassDependentModelsDialog = createAction('CLOSE_EDIT_AI_CLASS_DEPENDENT_MODELS_DIALOG')

export const openEditUniqueAiObjectConnectionsDialog = createAction('OPEN_EDIT_UNIQUE_AI_OBJECT_CONNECTIONS_DIALOG')
export const closeEditUniqueAiObjectConnectionsDialog = createAction('CLOSE_EDIT_UNIQUE_AI_OBJECT_CONNECTIONS_DIALOG')

export const getListOfDependentModels = createApiActions('GET_LIST_OF_DEPENDENT_MODELS')
export const createModelToClassDependency = createApiActions('CREATE_MODEL_TO_CLASS_DEPENDENCY')
export const deleteModelToClassDependency = createApiActions('DELETE_MODEL_TO_CLASS_DEPENDENCY')

export const searchAiModels = createApiActions('SEARCH_AI_MODELS')

export const addUniqueAiObjectConnection = createApiActions('ADD_UNIQUE_AI_OBJECT_CONNECTION')
export const deleteUniqueAiObjectConnection = createApiActions('DELETE_UNIQUE_AI_OBJECT_CONNECTION')
