import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Shapes } from '@tabeeb/shared/icons'

import { AnnotationType } from '@tabeeb/enums'
import { drawingActions, playerToolbarSelectors } from '@tabeeb/modules/playerToolbar'
import { usePopoverState } from '@tabeeb/shared/utils/hooks'

import ToolbarButton from '../ToolbarButton'
import ToolbarPopover from '../ToolbarPopover'
import AnnotationTypeSelect from '../AnnotationTypeSelect'

const BUTTON_NAME = 'shape'

const ShapeButton = () => {
  const dispatch = useDispatch()

  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const color = useSelector(playerToolbarSelectors.getSelectedColor)
  const type = useSelector(playerToolbarSelectors.getDrawingAnnotationType)
  const selected = useSelector((state) => playerToolbarSelectors.getIsButtonSelected(state, BUTTON_NAME))
  const isDrawingEnabled = useSelector(playerToolbarSelectors.getIsDrawingEnabled)

  const onClick = useCallback(() => {
    if (selected) {
      dispatch(drawingActions.onChangePressedButton({ pressedButton: '' }))
      dispatch(drawingActions.onDisableDrawing())
    } else {
      onOpen()
    }
  }, [dispatch, onOpen, selected])

  const onSelectShape = useCallback(
    (type) => {
      dispatch(drawingActions.onChangePressedButton({ pressedButton: BUTTON_NAME }))

      dispatch(drawingActions.onSetDrawingType(type))
      dispatch(drawingActions.onEnableDrawing())
    },
    [dispatch]
  )

  return (
    <>
      <ToolbarButton
        ref={anchorRef}
        Icon={Shapes}
        htmlColor={isDrawingEnabled && selected ? color : null}
        selected={selected || open}
        title='Add Shape annotation'
        onClick={onClick}
      />
      <ToolbarPopover anchorEl={anchorRef.current} open={open} onClose={onClose}>
        <AnnotationTypeSelect
          annotations={annotations}
          color={color}
          selected={selected ? type : null}
          enabled={isDrawingEnabled}
          onSelect={onSelectShape}
        />
      </ToolbarPopover>
    </>
  )
}

const annotations = [
  AnnotationType.Ellipse,
  AnnotationType.Ellipse_Filled,
  AnnotationType.Line,
  AnnotationType.Polygon,
  AnnotationType.Rectangle,
  AnnotationType.Rectangle_Filled,
  AnnotationType.ArrowEnd,
  AnnotationType.ArrowBoth,
]

export default memo(ShapeButton)
